// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: localStorage.getItem('userName') || '',
    successCount: 0,
    errorCount: 0
  },
  mutations: {
    setUserName(state, name) {
      state.userName = name;
      localStorage.setItem('userName', name);
    },
    setCounts(state, { success, error }) {
      state.successCount = success;
      state.errorCount = error;
    },
    clearUser(state) {
      state.userName = '';
      localStorage.removeItem('userName');
    },
  },
});
