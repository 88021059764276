import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      auth: true,
    },
    component: () => import('../components/layout/Layout.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/home/login.vue'),
      },
      {
        path: '/new_index',
        name: 'new_index',
        meta: {
          auth: true,
        },
        component: () => import('../views/home/new_index.vue'),
      },
    ],
  },
  {
    path: '/wordRemember',
    name: 'wordRemember',
    meta: {
      auth: true,
    },
    component: () => import('../views/wordRemember/index/index.vue'),
  },
  {
    path: '/wordLexicon/index',
    name: '/wordLexicon/index',
    meta: {
      auth: true,
    },
    component: () => import('../views/wordRemember/wordLexicon/index.vue'),
  },
  {
    path: '/smartIndex',
    name: '/smartIndex',
    meta: {
      auth: true,
    },
    component: () => import('../views/wordRemember/smartIndex.vue'),
  },
  {
    path: '/DanIndex',
    name: '/DanIndex',
    meta: {
      auth: true,
    },
    component: () => import('../views/wordRemember/DanIndex.vue'),
  },
  {
    path: '/WordsReport',
    name: '/WordsReport',
    meta: {
      auth: true,
    },
    component: () => import('../views/wordRemember/WordsReport.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token'); // 根据你的逻辑检查是否已登录
  // 检查是否试图访问根路径
  if (to.path === '/') {
    if (isAuthenticated) {
      // 已登录用户重定向到新页面
      next({ name: 'new_index' }); // 替换为你的目标路由
    } else {
      // 未登录用户重定向到登录页面
      next({ name: 'login' });
    }
  } else if (to.meta.auth && !isAuthenticated) {
    // 如果目标路由需要认证，且用户未登录，重定向到登录页面
    next({ name: 'login' });
  } else if (!isAuthenticated && !router.getRoutes().some(route => route.path === to.path)) {
    // 如果用户未登录且访问的路由不存在，重定向到登录页面
    next({ name: 'login' });
  } else {
    // 允许访问
    next();
  }
});

export default router;
